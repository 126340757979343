<template>
	<!-- 销售汇总 -->
	<div class="salesSummary">
		<!-- 导出模板 -->
		<div id="export" style="position: fixed;top: -999999px;">
			<div class="export-template">
				<div id="template-title">
					<div style="line-height: 70px;font-size: 22px;" align=center>
						<strong>
							<font>{{ supplier_info.supplier_name }}销售汇总</font>
						</strong>
					</div>
				</div>
				<div id="template-table">
					<table border=1 cellSpacing=0 cellPadding=1 width="100%"
						style="border-collapse:collapse;bordercolor:#333333;font-size: 14px;">
						<thead>
							<tr>
								<td width="5%">
									<div align=center><b>序号</b></div>
								</td>
								<td width="25%">
									<div align=center><b>商品名称</b></div>
								</td>
								<td width="10%">
									<div align=center><b>商品卖点</b></div>
								</td>
								<td width="10%">
									<div align=center><b>自定义属性</b></div>
								</td>
								<td width="10%">
									<div align=center><b>数量</b></div>
								</td>
								<td width="10%">
									<div align=center><b>销售金额</b></div>
								</td>
								<td width="10%">
									<div align=center><b>成本金额</b></div>
								</td>
								<td width="10%">
									<div align=center><b>毛利</b></div>
								</td>
								<td width="10%">
									<div align=center><b>毛利率</b></div>
								</td>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in tableData" :key="index">
								<td align=center>{{ index + 1 }}</td>
								<td align=center>{{ item.goods_name }}</td>
								<td align=center>{{ item.goods_intro }}</td>
								<td align=center>{{ item.attribute }}</td>
								<td align=center>{{ item.count }}</td>
								<td align=center>{{ item.amount }}</td>
								<td align=center>{{ item.cost_amount }}</td>
								<td align=center>{{ item.profit }}</td>
								<td align=center>{{ item.profit_rate }}</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>

		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
						<!-- <el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
                        </el-date-picker> -->
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">商品名称:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入商品名称" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="success" @click="handlePrint">打印</el-button>
				<el-button type="warning" @click="handleExport">导出</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
				<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
				<el-table-column prop="attribute" label="自定义属性" align="center"></el-table-column>
				<el-table-column prop="count" label="数量" align="center"></el-table-column>
				<el-table-column prop="amount" label="销售金额" align="center"></el-table-column>
				<el-table-column prop="cost" label="成本价" align="center"></el-table-column>
				<el-table-column prop="cost_amount" label="成本金额" align="center"></el-table-column>
				<el-table-column prop="profit" label="毛利" align="center"></el-table-column>
				<el-table-column prop="profit_rate" label="毛利率" align="center"></el-table-column>
			</Table>
		</div>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				// 仓库
				warehouseOptions: [],
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 供应商信息
				supplier_info: {},

			}
		},
		mounted() {
			// 仓库
			this.getWarehouseList()
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
		},
		methods: {
			getList() {
				this.$http.post(`erp/v1/order/summary`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
						this.total = this.tableData.length
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint() {
				setTimeout(() => {
					// var LODOP = getLodop();
					// // var titleStyle = "<style> .title-box{display: flex;justify-content: space-between} .title-box .l-item {line-height: 30px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					// LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, document.getElementById("export").innerHTML);
					// LODOP.PREVIEW()
					// this.printingList = []

					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("export")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			// 导出
			handleExport() {
				this.$http.post(`erp/v1/order/summary`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped></style>